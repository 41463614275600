import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "--color-darkL1",
	"padding": "80px 0 80px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"flex-direction": "column",
			"justify-content": "center",
			"padding": "0px 200px 0px 200px",
			"lg-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"font": "--headline1",
			"color": "--light",
			"text-align": "center",
			"sm-font": "normal 700 62px/1.2 \"Source Sans Pro\", sans-serif",
			"children": <>
				Verbinden wir uns{"  "}
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "--lead",
			"color": "--light",
			"text-align": "center",
			"children": "Lust auf eine unvergessliche Soiree mit Sport und Geselligkeit? So erreichen Sie uns:"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "mailto:blank?contact@cyroqau.com",
			"text-align": "center",
			"color": "--light",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"children": "contact@cyroqau.com"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "tel:+49 251 761254",
			"text-align": "center",
			"color": "--light",
			"font": "--headline3",
			"text-decoration-line": "initial",
			"margin": "0px 0px 16px 0px",
			"children": "+49 251 761254"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 48px 0px",
			"font": "--headline3",
			"color": "--light",
			"text-align": "center",
			"children": "Pankokstraße 2, 48153 Münster, Deutschland"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "center",
			"padding": "50px 0px 0px 0px",
			"border-color": "#232a44",
			"md-padding": "30px 0px 0px 0px",
			"md-flex-direction": "column",
			"lg-padding": "40px 0px 0px 0px",
			"align-items": "flex-end",
			"height": "auto",
			"md-align-items": "flex-start"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"md-margin": "0px 0px 25px 0px",
			"text-align": "center",
			"children": "cyroqau.com © 2023 Alle Rechte vorbehalten."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Text {...override("text2")} />
		</Box>
		<Box {...override("box1")}>
			<Text {...override("text3")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;